import React, {useState} from 'react'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import toastr from 'toastr'
import Box from '@mui/material/Box'
import UploadedFiles from './UploadedFiles'
import {green, grey} from '@mui/material/colors'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useAuth} from 'auth/useAuth'
import Papa from 'papaparse'

export default function Upload() {
  //putting a fetch to get the data for the files here and passing to the component UploadedFiles so we can update it when a file is uploaded here
  const [, , logoutAuth] = useAuth()

  const [csvData, setCsvData] = useState<Record<string, any>[] | null>(null)

  const checkHeaders = () => {
    if (csvData && csvData.length > 0) {
      const headers = Object.keys(csvData[0])
      // Check if headers match expected values
      if (headers.length > 0) {
        if (
          headers[0] != 'First' ||
          headers[1] != 'Middle' ||
          headers[2] != 'Last' ||
          headers[3] != 'Email' ||
          headers[4] != 'LinkedIn'
        ) {
          alert(
            'File header format incorrect: should be "First,Middle,Last,Email,LinkedIn"  ... but found "' +
              headers +
              '"'
          )
          return false
        }
        return true
      } else {
        alert('Headers are missing: should be First,Middle,Last,Email,LinkedIn')
        return false
      }
    }
    return false
  }

  const responseGet = () => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/data/files`,
    })
      .then(async (apiResponse: any) => {
        const rorder = apiResponse.data.sort((a: any, b: any) => {
          return new Date(b.uploaded_dttm).getTime() - new Date(a.uploaded_dttm).getTime()
        })
        setData(rorder)
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          //alert('error in upload')
          logoutAuth()
        }
        return []
      })
  }

  const [data, setData] = React.useState<any>(responseGet)

  const [selectedFile, setSelectedFile] = React.useState<any>(null)

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    if (file && file.type === 'text/csv') {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (e && e.target && e.target.result) {
          const result = Papa.parse(e.target.result as string, {header: true})
          setCsvData(result.data as Record<string, any>[])
          setSelectedFile(event.target.files[0])
        }
      }
      reader.readAsText(file)
    } else {
      alert('Please select a CSV file')
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const proceed = checkHeaders()

    if (proceed) {
      const formData = new FormData()
      formData.append('file', selectedFile)

      try {
        const response = await axios.post(`${apiHost}/api/files/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response && response.status === 200) {
          //rerun the fetch to show new file
          setSelectedFile(null)
          //reruns fetch after an upload
          responseGet()
        }
        console.log('File uploaded successfully:', response.data)
      } catch (error: any) {
        toastr.error(error)
        console.error('Error uploading file:', error)
      }
    }
  }

  //for the selected file to be cleared, I memoized this, but still if same file is selected, I think the clear is not working
  const uploadFileMemo = React.useMemo(() => {
    return (
      <Box display={'flex'} alignItems={'center'} bgcolor={grey[100]} p={1}>
        <label
          htmlFor='file-upload'
          style={{
            border: '1px solid #ccc',
            display: 'inline-block',
            padding: '6px 12px',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
        >
          Choose File To Upload
          <input
            id='file-upload'
            type='file'
            accept={'.csv'}
            onChange={handleFileChange}
            style={{display: 'none'}}
          />
        </label>
        {!selectedFile && (
          <Box display={'flex'} gap={1} ml={2}>
            <Typography variant={'body2'} color={grey[800]}>
              CSV only please, format:
            </Typography>
            <Box display={'flex'} flexDirection={'column'} bgcolor={'white'} pl={1} pr={1}>
              <Typography variant={'caption'} color={grey[600]}>
                First,Middle,Last,Email,LinkedIn
              </Typography>
              <Typography variant={'caption'} color={grey[600]}>
                Mark,Anthony,Miller,mark@thirdcoastfederal.com,https://www.linkedin.com/in/softwaremark/{' '}
              </Typography>
            </Box>
          </Box>
        )}
        {/* <input id='file-upload' type='file' style={{display: 'none'}} /> */}
        {selectedFile ? (
          <Typography fontWeight={700} color={grey[700]} sx={{ml: 2, mr: 2}}>
            {selectedFile.name}
          </Typography>
        ) : (
          <></>
        )}
        {selectedFile && (
          <Button
            variant={'outlined'}
            size='small'
            onClick={() => setSelectedFile(null)}
            sx={{
              borderRadius: '0px',
              boxShadow: 'none',
              ml: 2,
              mr: 2,
              color: '#000000',
              border: '1px solid black',
            }}
          >
            Clear
          </Button>
        )}
        {selectedFile && (
          <Button
            variant={'contained'}
            size='small'
            type='submit'
            sx={{borderRadius: '0px', boxShadow: 'none', ml: 2, mr: 2}}
          >
            Upload
          </Button>
        )}
      </Box>
    )
  }, [selectedFile, setSelectedFile])

  return (
    <Box mb={10}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h5'} color={grey[700]}>
          File Upload and Processing
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>{uploadFileMemo}</form>

      {data && <UploadedFiles data={data} callback={responseGet} />}
      <br />
    </Box>
  )
}
