import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import {alpha, styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import LanguageIcon from '@mui/icons-material/Language'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import Upload from './upload'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'
import InterestsIcon from '@mui/icons-material/Interests'
import ExperienceDataGrid from './ExperienceDataGrid'
import WorkIcon from '@mui/icons-material/Work'
import {blue, blueGrey, brown, deepOrange, green, grey, lightBlue, lime} from '@mui/material/colors'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import ProfilesDataGrid from './ProfilesDataGrid'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PublicationsDataGrid from './PublicationsDataGrid'
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import HonorsAwardsDataGrid from './HonorsAwardsDataGrid'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import SchoolIcon from '@mui/icons-material/School'
import PlaceIcon from '@mui/icons-material/Place'
import PersonIcon from '@mui/icons-material/Person'
import EducationDataGrid from './EducationDataGrid'
import Drawer from '@mui/material/Drawer'
import {IWholeScrape} from 'interfaces/IWholeScrape'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import ShowMoreText from './ShowMoreText'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import HorizontalSquareChips from './HorizontalSquareChips'
import IconButton from '@mui/material/IconButton'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  )
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  interface StyledTabsProps {
    children?: React.ReactNode
    value: number
    onChange: (event: React.SyntheticEvent, newValue: number) => void
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{children: <span className='MuiTabs-indicatorSpan' />}} />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  })

  interface StyledTabProps {
    label: string
  }

  const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: '#fff',
      '&.Mui-selected': {
        color: '#9ccaf7',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    })
  )
  const [drawerData, setDrawerData] = React.useState<any>()

  const profileDataDrawer = React.useMemo(() => {
    const profile: IWholeScrape =
      drawerData && Array.isArray(drawerData)
        ? (drawerData[0].raw_profile as unknown as IWholeScrape)
        : {}
    const allData =
      drawerData && Array.isArray(drawerData) && drawerData.length ? drawerData[0] : undefined
    return (
      <Box display={'flex'}>
        <Drawer
          elevation={0}
          PaperProps={{
            sx: {
              width: '75%',

              padding: '15px',
              paddingTop: '0px',
              bgcolor: '#083866',
              color: grey[50],
            },
          }}
          slotProps={{backdrop: {style: {backgroundColor: 'transparent'}}}}
          anchor={'left'}
          open={Boolean(drawerData)}
          onClose={() => setDrawerData(undefined)}
        >
          <Box sx={{bgcolor: '#083866'}} justifyContent={'flex-end'} display={'flex'}>
            {' '}
            <IconButton onClick={() => setDrawerData(undefined)}>
              <CancelIcon sx={{color: 'white', fontSize: '30px'}} />
            </IconButton>
          </Box>
          <Card elevation={0} sx={{flex: '1 1 auto', overflowY: 'auto'}}>
            <CardContent>
              <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} alignItems={'center'}>
                    {/* profile avatar pic */}
                    <Box display={'flex'} alignItems={'baseline'} mr={2}>
                      <Typography variant={'h5'}>
                        {profile?.avatar ? (
                          <>
                            <Avatar
                              alt={'profile pic'}
                              src={profile.avatar}
                              sx={{width: 120, height: 120}}
                            />
                          </>
                        ) : (
                          <PersonIcon
                            sx={{
                              fontSize: '4em',
                              backgroundColor: grey[500],
                              borderRadius: '50%',
                              opacity: '80%',
                              color: 'white',
                              margin: 5,
                              marginRight: 0,
                            }}
                            fontSize={'inherit'}
                            color={'success'}
                          />
                        )}
                      </Typography>
                    </Box>

                    {/* name and title */}
                    <Box display={'flex'} flexDirection={'column'}>
                      <Box display={'flex'} gap={2}>
                        {profile?.name && (
                          <Typography variant={'h3'} color={grey[600]}>
                            {profile.name}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        {profile?.current_company?.title && (
                          <Box display={'flex'} ml={1} alignItems={'center'}>
                            <Typography
                              variant={'caption'}
                              fontWeight={600}
                              fontSize={'1.1rem'}
                              color={grey[700]}
                            >
                              <LabelImportantRoundedIcon sx={{mr: 0.5, fontSize: '1em'}} />
                              {profile?.current_company?.title}
                              <br />
                              {profile?.current_company?.name}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />

              <Box>
                <Box display={'flex'} justifyContent={'space-between'} m={2} mt={5}>
                  <Box display={'flex'} flexDirection={'column'} mr={2}>
                    {allData?.email && (
                      <Typography>
                        <EmailIcon sx={{mr: 1}} />
                        {allData?.email}
                      </Typography>
                    )}
                    {profile?.city && (
                      <Typography>
                        <PlaceIcon sx={{mr: 1}} />
                        {profile?.city}
                      </Typography>
                    )}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} mr={2}>
                    {profile?.url && (
                      <Typography>
                        <LinkedInIcon sx={{mr: 1}} />

                        <Link href={profile?.url ?? ''}>
                          {' '}
                          LinkedIn <OpenInNewIcon fontSize='small' />{' '}
                        </Link>
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box m={2} mb={5} color={grey[700]}>
                  <Typography variant={'h5'}>About</Typography>
                  {allData?.about ? (
                    <ShowMoreText
                      emptyCondition='Add a bio or description'
                      variant={'h6'}
                      maxLen={1000}
                      text={allData?.about ?? ''}
                      inheritColor={true}
                    />
                  ) : (
                    <Typography variant={'body1'} color={grey[600]}>
                      No Description found
                    </Typography>
                  )}
                </Box>

                <Box display={'flex'} justifyContent={'space-between'}>
                  {/* experience / work history */}
                  <Card elevation={0} sx={{flex: '1 1 auto'}}>
                    <CardHeader
                      title={
                        <Box display={'flex'} alignItems={'center'}>
                          <WorkIcon sx={{mr: 1}} />
                          Experience
                        </Box>
                      }
                    />
                    <CardContent>
                      {profile?.experience?.length && (
                        <>
                          {profile?.experience?.map((exp, idx) => {
                            return (
                              <Box
                                key={`box_exp${idx}`}
                                display={'flex'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Box
                                    mb={5}
                                    sx={{
                                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                      width: '500px',
                                      minHeight: '120px',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      sx={{backgroundColor: `${alpha(lightBlue[50], 0.5)}`}}
                                      p={1}
                                    >
                                      <Typography variant={'h6'} color={grey[900]} fontWeight={700}>
                                        {exp?.company}{' '}
                                      </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{backgroundColor: `${alpha(grey[50], 0.5)}`, p: 1}}>
                                      <Box display={'flex'} flexDirection={'column'}>
                                        {exp?.title && (
                                          <Chip
                                            label={
                                              <Typography variant={'body1'}>
                                                {' '}
                                                <LabelImportantRoundedIcon
                                                  sx={{mr: 0.5, fontSize: '1em'}}
                                                />
                                                {exp.title}
                                              </Typography>
                                            }
                                            size={'small'}
                                            sx={{
                                              borderRadius: '0px',
                                              justifyContent: 'start',
                                              m: 0.5,
                                              fontWeight: 700,
                                              bgcolor: `${alpha(blueGrey[50], 0.5)}`,
                                              color: 'black',
                                            }}
                                          />
                                        )}
                                      </Box>
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        minHeight={'50px'}
                                        m={1}
                                      >
                                        {exp?.location && (
                                          <Typography variant={'h6'} color={grey[600]}>
                                            <PlaceIcon sx={{mr: 1}} />
                                            {exp?.location}
                                          </Typography>
                                        )}

                                        <Typography
                                          variant='body1'
                                          color={brown[400]}
                                          fontWeight={600}
                                        >
                                          {exp?.start_date && exp?.start_date}-
                                          {exp?.end_date && exp?.end_date}
                                        </Typography>
                                      </Box>
                                      <Box display={'flex'} flexDirection={'column'} m={1}>
                                        {exp.url && (
                                          <Link
                                            href={exp?.url ?? '#'}
                                            target='_blank'
                                            rel='noreferrer'
                                            color={blue[600]}
                                          >
                                            Company Linkedin site
                                            <OpenInNewIcon fontSize={'small'} />
                                          </Link>
                                        )}
                                        {exp?.description && (
                                          <Box bgcolor={'white'} p={2}>
                                            <ShowMoreText maxLen={200} text={exp.description} />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            )
                          })}{' '}
                        </>
                      )}
                    </CardContent>
                  </Card>
                  {/* education */}
                  <Card elevation={0} sx={{flex: '1 1 auto'}}>
                    <CardHeader
                      title={
                        <Box display={'flex'} alignItems={'center'}>
                          <SchoolIcon sx={{mr: 1}} />
                          Education
                        </Box>
                      }
                    />
                    <CardContent>
                      {profile?.education?.length && (
                        <>
                          {profile?.education?.map((edu, idx) => {
                            return (
                              <Box
                                key={`box_exp${idx}`}
                                display={'flex'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Box
                                    mb={5}
                                    sx={{
                                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                      width: '500px',
                                      minHeight: '190px',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      sx={{backgroundColor: `${alpha(green[50], 0.5)}`}}
                                      p={1}
                                    >
                                      <Typography variant={'h6'} color={grey[900]} fontWeight={700}>
                                        {edu?.title}
                                        {<br />}
                                      </Typography>
                                    </Box>
                                    <Divider />
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      p={1}
                                      minHeight={'50px'}
                                      sx={{backgroundColor: `${alpha(grey[50], 0.5)}`}}
                                    >
                                      {edu?.degree && (
                                        <Chip
                                          label={
                                            <Typography variant={'body1'}>
                                              <LabelImportantRoundedIcon
                                                sx={{mr: 0.5, fontSize: '1em'}}
                                              />
                                              {edu?.degree}
                                            </Typography>
                                          }
                                          size={'small'}
                                          sx={{
                                            borderRadius: '0px',
                                            justifyContent: 'start',
                                            m: 0.5,
                                            fontWeight: 700,
                                            bgcolor: `${alpha(lime[100], 0.5)}`,
                                            color: 'black',
                                          }}
                                        />
                                      )}
                                      {edu?.field && (
                                        <Box ml={2}>
                                          <Chip
                                            label={
                                              <Typography variant={'body1'}>
                                                <DoneOutlineIcon sx={{mr: 0.5, fontSize: '1em'}} />
                                                {edu?.field}
                                              </Typography>
                                            }
                                            size={'small'}
                                            sx={{
                                              borderRadius: '0px',
                                              justifyContent: 'start',
                                              m: 0.5,
                                              fontWeight: 700,
                                              bgcolor: `${alpha(blueGrey[50], 0.3)}`,
                                              color: 'black',
                                            }}
                                          />
                                        </Box>
                                      )}

                                      <Typography
                                        variant='body1'
                                        color={brown[400]}
                                        fontWeight={600}
                                      >
                                        {edu?.start_year && edu?.start_year} -
                                        {edu?.end_year && edu?.end_year}
                                      </Typography>

                                      <Box display={'flex'} flexDirection={'column'} m={1}>
                                        {edu.url && (
                                          <Link
                                            href={edu.url ?? '#'}
                                            target='_blank'
                                            rel='noreferrer'
                                            color={blue[600]}
                                          >
                                            School Linkedin site
                                            <OpenInNewIcon fontSize={'small'} />
                                          </Link>
                                        )}
                                        {edu?.description && (
                                          <Box bgcolor={'white'} p={2}>
                                            <ShowMoreText maxLen={200} text={edu.description} />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            )
                          })}{' '}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Box>
                {/* publications */}
                {profile?.publications?.length && (
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Card elevation={0} sx={{flex: '1 1 auto'}}>
                      <CardHeader
                        title={
                          <Box display={'flex'} alignItems={'center'}>
                            <WorkspacePremiumIcon sx={{mr: 1}} />
                            Publications
                          </Box>
                        }
                      />
                      <CardContent>
                        {profile?.publications?.length && (
                          <>
                            {profile?.publications?.map((pub, idx) => {
                              return (
                                <Box
                                  key={`box_exp${idx}`}
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        minWidth: '300px',
                                        maxWidth: '100%',
                                        backgroundColor: alpha(grey[50], 0.5),
                                        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
                                      }}
                                    >
                                      <Box sx={{backgroundColor: grey[50]}} p={1}>
                                        <Typography variant={'h5'} color={grey[900]}>
                                          {pub?.title}{' '}
                                        </Typography>
                                        <Typography variant={'h6'} color={grey[700]}>
                                          {pub?.subtitle}{' '}
                                        </Typography>
                                      </Box>
                                      <Box display={'flex'} flexDirection={'column'} p={1}>
                                        {pub?.description && (
                                          <Typography variant={'body1'} color={grey[600]}>
                                            {pub?.description}
                                          </Typography>
                                        )}
                                        {pub?.date && (
                                          <Typography variant={'h6'} color={grey[600]}>
                                            {pub?.date}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                )}
                {/* skills */}

                {/* certifications */}
                {profile?.certifications?.length && (
                  <Box display={'flex'} justifyContent={'space-between'}>
                    {/* certification */}
                    <Card elevation={0} sx={{flex: '1 1 auto'}}>
                      <CardHeader
                        title={
                          <Box display={'flex'} alignItems={'center'}>
                            <WorkspacePremiumIcon sx={{mr: 1}} />
                            Certifications
                          </Box>
                        }
                      />
                      <CardContent>
                        {profile?.certifications?.length && (
                          <Box display={'flex'} gap={2}>
                            {profile?.certifications?.map((cert, idx) => {
                              return (
                                <Box
                                  key={`box_exp${idx}`}
                                  display={'flex'}
                                  justifyContent={'space-between'}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
                                        minWidth: '300px',
                                        maxWidth: '100%',
                                        minHeight: '160px',
                                        backgroundColor: alpha(deepOrange[50], 0.5),
                                      }}
                                    >
                                      <Box display={'flex'} sx={{backgroundColor: grey[50]}} p={1}>
                                        <Typography variant={'h6'} color={grey[900]}>
                                          {cert?.title}{' '}
                                        </Typography>
                                      </Box>
                                      {cert?.meta && (
                                        <Typography variant={'body1'} color={grey[900]} ml={3}>
                                          {cert?.meta}{' '}
                                        </Typography>
                                      )}

                                      <Box display={'flex'} flexDirection={'column'} m={1} p={1}>
                                        {cert?.subtitle && (
                                          <Typography variant={'h6'} color={grey[600]}>
                                            {cert?.subtitle}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                )}
                {/* honors_and_awards */}
                {profile?.honors_and_awards?.length && (
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Card elevation={0} sx={{flex: '1 1 auto'}}>
                      <CardHeader
                        title={
                          <Box display={'flex'} alignItems={'center'}>
                            <InterestsIcon sx={{mr: 1}} />
                            Honors & Awards
                          </Box>
                        }
                      />
                      <CardContent>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          {profile?.honors_and_awards?.length && (
                            <Box>
                              {profile?.honors_and_awards?.map((ha, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
                                      minWidth: '300px',
                                      maxWidth: '100%',
                                    }}
                                  >
                                    {ha.title && (
                                      <Box display={'flex'} sx={{backgroundColor: grey[50]}} p={1}>
                                        <Typography variant={'h6'} color={grey[900]}>
                                          {ha?.title}{' '}
                                        </Typography>
                                      </Box>
                                    )}
                                    {ha.description && (
                                      <Box display={'flex'} sx={{backgroundColor: grey[50]}} p={1}>
                                        <Typography variant={'h6'} color={grey[900]}>
                                          {ha?.description}{' '}
                                        </Typography>
                                      </Box>
                                    )}
                                    <Divider />
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      m={1}
                                      p={1}
                                      minHeight={'90px'}
                                    >
                                      <Link href={ha.url} variant='body1'>
                                        {ha.url}
                                      </Link>
                                    </Box>
                                  </Box>
                                )
                              })}
                            </Box>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                )}
                {/* languages */}
                {allData?.languages && allData?.languages.length ? (
                  <Box display={'flex'}>
                    <Card elevation={0} sx={{flex: '1 1 auto'}}>
                      <CardHeader
                        title={
                          <Box display={'flex'} alignItems={'center'}>
                            <LanguageIcon sx={{mr: 1}} />
                            Languages
                          </Box>
                        }
                      />
                      <CardContent>
                        <HorizontalSquareChips
                          items={allData?.languages?.map((l: any) => l.title)}
                          maxItems={15}
                          color={green[50]}
                          fontsize={'1.2em'}
                          moreString={'Show more skills'}
                          lessString={'Show less skills'}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </CardContent>
          </Card>
          <hr />
          <br />
        </Drawer>
      </Box>
    )
  }, [drawerData])

  return (
    <Box sx={{width: '100%'}}>
      {profileDataDrawer}
      <Box sx={{bgcolor: '#0a3866'}}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label='Profiles' />
          <StyledTab label='Experiences' />
          <StyledTab label='Education' />
          <StyledTab label='Publications' />
          <StyledTab label='Honors & Awards' />
          <StyledTab label='File Upload and Processing' />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography variant={'h5'} color={grey[700]}>
          Profiles
        </Typography>
        <ProfilesDataGrid setProfileDataDrawer={setDrawerData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant={'h5'} color={grey[700]}>
          Experiences
        </Typography>
        <ExperienceDataGrid />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography variant={'h5'} color={grey[700]}>
          Education
        </Typography>
        <EducationDataGrid />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography variant={'h5'} color={grey[700]}>
          Publications
        </Typography>
        <PublicationsDataGrid />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography variant={'h5'} color={grey[700]}>
          Honors & Awards
        </Typography>
        <HonorsAwardsDataGrid />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Upload />
      </TabPanel>
    </Box>
  )
}
