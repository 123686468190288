import toastr from 'toastr'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {GoogleLogin, googleLogout} from '@react-oauth/google'
import {grey} from '@mui/material/colors'
import axios from 'axios'
import {useAuthProvider} from 'auth/AuthProvider'
import {useAuth} from 'auth/useAuth'
import LinearProgress from '@mui/material/LinearProgress'
import {iGoogleUser} from 'interfaces/iGoogleUser'

export default function LoginForm(): React.ReactElement {
  const url = new URL(window.location.href)
  const code = url.searchParams.get('code')
  // const userAgent = navigator.userAgent
  const [user, loginAuth, logoutAuth, setToken] = useAuth()
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false)
  const {currentUser, setCurrentUser, logout} = useAuthProvider()
  const navigate = useNavigate()

  // const callTestRoute = async () => {
  //   exec(`/api/test/test`, {
  //     onComplete: (res) => alert('called the test route'),
  //     onError: (error: any) => {
  //       toastr.error('failed test route')
  //     },
  //     excludeAuthHeaders: false,
  //   })
  // }

  // const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault()
  // }

  // const responseSuccessGoogle = (response: any) => {
  //   setLoadingAuth(true)
  //   axios({
  //     method: 'POST',
  //     url: `${apiHost}/api/auth/googlelogin`,
  //     data: {tokenId: response.credential},
  //   })
  //     .then(async (apiResponse: any) => {
  //       //now set front end auth
  //       console.log(apiResponse)
  //       console.log(apiResponse.data)
  //       console.log(apiResponse.data.token)
  //       await loginAuth(apiResponse.data.token)
  //       setLoadingAuth(false)
  //       navigate('/')
  //       window.location.reload()
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       setLoadingAuth(false)
  //     })
  // }

  // const responseErrorGoogle = (response: any) => {
  //   console.log(response)
  // }

  // const googleLogOut = () => {
  //   googleLogout()
  //   setCurrentUser(null)
  // }

  useEffect(() => {
    if (!currentUser && !code) {
      window.location.href = `https://tcfskunkworks.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID ?? ''}&redirect_uri=${process.env.REACT_APP_IS_LOCAL === 'true' ? 'http://localhost:3000/login' : process.env.REACT_APP_COGNITO_REDIRECT_URI}`
    }
    if (!currentUser && code) {
      //send the code to the backend to get the user and token to set auth
      const stop = 'here'

      const url = new URL(`https://tcfskunkworks.auth.us-east-1.amazoncognito.com/oauth2/token`)
      url.searchParams.set('grant_type', 'authorization_code')
      url.searchParams.set('code', code)
      url.searchParams.set('client_id', process.env.REACT_APP_CLIENT_ID ?? '')
      url.searchParams.set(
        'redirect_uri',
        process.env.REACT_APP_IS_LOCAL === 'true'
          ? 'http://localhost:3000/login'
          : (process.env.REACT_APP_COGNITO_REDIRECT_URI ?? '')
      )
      url.searchParams.set('scope', 'email openid phone')
      const strHref = url.href
      const cognitoResponse = axios
        .post(
          strHref,
          {},
          {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(async (apiResponse: any) => {
          //alert('after post to get cognito response and before loginAuth call from loginForm')
          const done = await loginAuth(apiResponse.data.id_token)
          setLoadingAuth(false)
          if (done) {
            //a reload is necessary in order to get the provider to load and set the axios calls to have the token where navigate will try to call the api without a token if no reload
            window.location.href =
              process.env.REACT_APP_IS_LOCAL === 'true'
                ? 'http://localhost:3000'
                : 'https://www.tcfskunkworks.com'
          }
        })
        .catch((err) => {
          const stop = 'here'
          //alert('error in loginForm')
          logout()
        })
      setLoadingAuth(true)
    }
    // if (currentUser) {
    //   navigate('/')
    // }
  }, [code, currentUser, user])

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#083866',
      }}
    >
      {loadingAuth ? (
        <Box display={'flex'} flexDirection={'column'}>
          <Typography color={grey[50]} variant={'h4'}>
            Logging in....
          </Typography>
          <LinearProgress variant={'indeterminate'} />
        </Box>
      ) : (
        <Box
          style={{
            width: '400px',
            backgroundColor: '#eeeeee',
            paddingLeft: '1em',
            paddingRight: '2em',
            paddingTop: '2em',
            paddingBottom: '1em',
            borderRadius: '4px',
          }}
        >
          <Box display={'flex'} justifyContent={'center'}>
            {/* {currentUser ? (
              <div>
                <img src={currentUser.picture} alt='user image' />
                <h3>User is already Logged in</h3>
                <p>Name: {currentUser.name}</p>
                <p>Email Address: {currentUser.email}</p>
                <br />
                <br />
                <button onClick={googleLogOut}>Log out of google</button>
              </div>
            ) : (
              //@ts-ignore
              <GoogleLogin
                onSuccess={responseSuccessGoogle}
                //@ts-ignore
                onError={responseErrorGoogle}
                cookiePolicy={'single_host_origin'}
              />
            )} */}
            <Box display={'flex'} flexDirection={'column'}>
              <Typography color={grey[50]} variant={'h4'}>
                Logging in....
              </Typography>
              <LinearProgress variant={'indeterminate'} />
            </Box>
          </Box>
          {/* <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h6'}></Typography>
            <Button onClick={() => navigate('/')}>
              <Typography variant={'body2'}>Cancel</Typography>
            </Button>
          </Box> */}
        </Box>
      )}
      {/* <Button onClick={() => callTestRoute()}>Try Test Route</Button> */}
    </Box>
  )
}
