import AddIcon from '@mui/icons-material/Add'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import RemoveIcon from '@mui/icons-material/Remove'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {grey} from '@mui/material/colors'
import {useState} from 'react'

export default function HorizontalSquareChips({
  items,
  maxItems,
  moreString,
  lessString,
  color,
  stackVertical,
  size,
  borderRadius,
  fontsize,
}: {
  items: string[]
  maxItems?: number
  moreString?: string
  lessString?: string
  emptyCondition?: string
  stackVertical?: boolean
  size?: 'small' | 'medium'
  color?: string
  borderRadius?: string
  fontsize?: string
}): React.ReactElement {
  const [isCollapse, setIsCollapse] = useState(true)
  const [open, setOpen] = useState<boolean>(false)

  const ItemList = () => {
    const itemsTemp = isCollapse ? items.slice(0, maxItems) : items
    return (
      <Box
        sx={{
          '& div': {
            borderRadius: borderRadius ? borderRadius : '0px',
          },
        }}
      >
        <Stack direction={stackVertical ? 'column' : 'row'} spacing={1} flexWrap={'wrap'}>
          {itemsTemp.map((c, idx) => {
            return (
              <Box display={'inline-flex'} key={idx}>
                <Chip
                  key={idx}
                  label={c}
                  size={size ? size : 'medium'}
                  sx={{
                    m: 0.5,
                    fontWeight: 600,
                    bgcolor: color ? color : grey[200],
                    fontSize: fontsize ? fontsize : '10pt',
                  }}
                />
              </Box>
            )
          })}
        </Stack>
      </Box>
    )
  }

  return (items && items.length) > (maxItems ?? 0) ? (
    <Box component={'span'} color={color ? color : grey[800]} fontSize={'inherit'}>
      <>
        {ItemList()}
        <a onClick={() => setIsCollapse((isCollapse) => !isCollapse)}>
          {isCollapse ? (
            <Typography variant={'subtitle1'} sx={{cursor: 'pointer'}}>
              <AddIcon color={'info'} fontSize='medium' sx={{cursor: 'pointer'}} /> {moreString}
            </Typography>
          ) : lessString ? (
            <Typography variant={'subtitle1'} sx={{cursor: 'pointer'}}>
              <RemoveIcon color={'info'} fontSize='medium' sx={{cursor: 'pointer'}} /> {lessString}
            </Typography>
          ) : (
            <ExpandLessIcon color={'info'} fontSize='large' sx={{cursor: 'pointer'}} />
          )}
        </a>
      </>
    </Box>
  ) : (
    <Box component={'span'} color={color ? color : grey[800]} fontSize={'inherit'}>
      <>{ItemList()}</>
    </Box>
  )
}
