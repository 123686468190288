import {Button} from '@mui/material'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import Footer from 'layouts/footer'
import LandingBar from 'layouts/LandingBar'
import MainLayout from 'layouts/MainLayout'

export default function Revoke(): React.ReactElement {
  //Mock Data
  const revoke = (response: any) => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/auth/revoke`,
    })
      .then(async (apiResponse: any) => {
        alert('revoked')
      })
      .catch((err: any) => {
        return undefined
        console.log(err)
      })
  }

  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <Button onClick={revoke}>Revoke</Button>
    </MainLayout>
  )
}
