import {jwtDecode} from 'jwt-decode'
import {useState} from 'react'
import toastr from 'toastr'
import useOnActionPost from '../hooks/common/useOnActionPost'
import {useNavigate} from 'react-router-dom'
import {useAuthProvider} from './AuthProvider'
import axios from 'axios'

export const useAuth = () => {
  const navigate = useNavigate()
  const {setCurrentUser} = useAuthProvider()

  const [token, setTokenInternal] = useState<any>(() => {
    return localStorage.getItem('token')
  })

  const getPayloadFromToken = (token: any) => {
    if (token) {
      const decoded = jwtDecode(token)
      console.log('DECODED:' + decoded)
      //const encodedPayload = token.split('.')[1]
      return decoded
    }
    return token
  }

  const [user, setUser] = useState(() => {
    if (!token) return null
    return getPayloadFromToken(token)
  })

  const setToken = (newToken?: string) => {
    //alert('set token is being called')
    if (newToken) {
      //alert('new token is being set')
      localStorage.setItem('token', newToken)
      setTokenInternal(newToken)
      setUser(getPayloadFromToken(newToken))
      setCurrentUser(getPayloadFromToken(newToken))
    }
  }

  const loginAuth = async (cognitoToken?: string) => {
    let retval: {token: string} | undefined
    if (cognitoToken) {
      console.log('google token, google auth call:' + cognitoToken)
      setToken(cognitoToken)
      return true
    }
  }

  const logoutAuth = () => {
    //alert('logout is being called')
    localStorage.removeItem('token')
    setTokenInternal(undefined)
    setUser(undefined)
    setCurrentUser(undefined)
    //this should send them back to login or home based on routes with no user
    //window.location.reload()
    const url = new URL(`https://tcfskunkworks.auth.us-east-1.amazoncognito.com/logout`)

    url.searchParams.set('client_id', process.env.REACT_APP_CLIENT_ID ?? '')
    url.searchParams.set(
      'redirect_uri',
      process.env.REACT_APP_IS_LOCAL === 'true'
        ? 'http://localhost:3000/login'
        : (process.env.REACT_APP_COGNITO_REDIRECT_URI ?? '')
    )
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('scope', 'openid email')

    window.location.href = url.href
  }

  return [user, loginAuth, logoutAuth, setToken]
}
