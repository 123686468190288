import axios from 'axios'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {apiHost} from 'config/apiConfig'
import React from 'react'
import Box from '@mui/material/Box'
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid'
import {IExperience} from 'interfaces/IExperience'
import {useAuth} from 'auth/useAuth'
import ShowMoreText from './ShowMoreText'
import InteractiveLoading from './InteractiveLoading'
import {emFormatStandardDate} from 'utils/dateFormatter'

export default function PublicationsDataGrid() {
  const [, , logoutAuth] = useAuth()
  const responseGetDataGridExperience = (response: any) => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/data/publications`,
    })
      .then(async (apiResponse: any) => {
        setData(apiResponse.data as unknown as IExperience[])
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          logoutAuth()
        }
        return undefined
      })
  }

  const [data, setData] = React.useState<any>(responseGetDataGridExperience)

  const columns: GridColDef<(typeof data)[number]>[] = [
    {
      field: 'id',
      headerName: 'TCF Identifier',
      width: 120,
      editable: false,
      hideable: true,
      description: 'TCF ID in database',
    },
    {
      field: 'linkedin_id',
      headerName: 'Linkedin ID',
      description: 'Linkedin identifier, comes from SCALE provided url',
      width: 350,
      editable: false,
      hideable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      description: 'Email provided by scale',
      width: 220,
      editable: false,
      hideable: true,
    },
    {
      field: 'full_name',
      headerName: 'Name',
      description: 'Full name of person',
      width: 220,
      editable: false,
      hideable: true,
    },
    {
      field: 'title',
      headerName: 'Publication Title',
      description: 'Publication Title from Linkedin',
      width: 320,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'subtitle',
      headerName: 'Publication Subtitle',
      description: 'Publication Subtitle from Linkedin',
      width: 320,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      description: 'Publication description scraped from Linkedin',
      width: 600,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={80} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'date_str',
      headerName: 'Date Linkedin',
      description: 'Linkedin string date for publication',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'date_dt_tcf',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'Date TCF',
      description: 'TCF date created from Linedin string date for filtering and sorting purposes',
      width: 150,
      editable: false,
      hideable: true,
    },
  ]

  return (
    <Box display={'flex'}>
      <Box sx={{height: 625, width: '100%'}}>
        {!data ? (
          <InteractiveLoading loading={true} success={false} />
        ) : (
          <DataGrid
            slots={{toolbar: GridToolbar}}
            getRowHeight={() => 'auto'}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns
                  id: false,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            density='compact'
          />
        )}
      </Box>
    </Box>
  )
}
