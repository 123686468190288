import axios from 'axios'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {apiHost} from 'config/apiConfig'
import React from 'react'
import Box from '@mui/material/Box'
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid'
import {useAuth} from 'auth/useAuth'
import InteractiveLoading from './InteractiveLoading'
import {emFormatStandardDate} from 'utils/dateFormatter'
import ShowMoreText from './ShowMoreText'
import Button from '@mui/material/Button'

export default function ProfilesDataGrid({
  setProfileDataDrawer,
}: {
  setProfileDataDrawer: React.Dispatch<React.SetStateAction<any>>
}) {
  const [, , logoutAuth] = useAuth()
  const responseGetDataGrid = (response: any) => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/profile/profiles`,
    })
      .then(async (apiResponse: any) => {
        setData(apiResponse.data)
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          //alert('error in profiles datagrid')
          logoutAuth()
        }
        return undefined
        console.log(err)
      })
  }

  const handleSetDrwerData = (id: string) => {
    if (id) {
      axios({
        method: 'GET',
        url: `${apiHost}/api/profile/profiles/${id}`,
      })
        .then(async (apiResponse: any) => {
          setProfileDataDrawer(apiResponse.data)
        })
        .catch((err) => {
          if (err.message.includes('401')) {
            //alert('error in profiles datagrid')
            logoutAuth()
          }
        })
    }
  }

  const [data, setData] = React.useState<any>(responseGetDataGrid)
  const [profile, setProfile] = React.useState<any>()
  const [show, setShow] = React.useState<boolean>(false)

  //Make a scrolling dialog to hold the scraped profile

  const columns: GridColDef<(typeof data)[number]>[] = [
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e: any) => {
    //       e.stopPropagation() // don't select this row after clicking

    //       const api: GridApi = params.api
    //       const thisRow: Record<string, any> = {}

    //       api
    //         .getAllColumns()
    //         .filter((c) => c.field !== '__check__' && !!c)
    //         .forEach((c) => (thisRow[c.field] = params.row))
    //       //return dialog of raw profile
    //       setProfile(thisRow.action)
    //       setShow(true)
    //       //return alert(JSON.stringify(thisRow.action.raw_profile, null, 4))
    //     }

    //     return (
    //       <Button
    //         variant={'text'}
    //         size='small'
    //         onClick={onClick}
    //         sx={{fontSize: '12px', p: 0, m: 0}}
    //       >
    //         Profile
    //       </Button>
    //     )
    //   },
    // },
    {
      field: 'id',
      headerName: '',
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <Button
              onClick={() => handleSetDrwerData(params.value ?? '')}
              sx={{m: 0, p: 0, fontSize: '12px', textAlign: 'left', justifyContent: 'flex-start'}}
            >
              <VisibilityIcon />
            </Button>
          </Box>
        ) : (
          <></>
        ),
      width: 40,
      editable: false,
      hideable: true,
    },
    {
      field: 'linkedin_id',
      headerName: 'Linkedin ID',

      width: 350,
      editable: false,
      hideable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
      editable: false,
      hideable: true,
    },
    {
      field: 'first',
      headerName: 'First',
      width: 250,
      editable: false,
      hideable: true,
    },
    {
      field: 'last',
      headerName: 'Last',
      width: 250,
      editable: false,
      hideable: true,
    },
    {
      field: 'middle',
      headerName: 'Middle',
      width: 250,
      editable: false,
      hideable: true,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 360,
      valueGetter: (value, row) => `${row.first || ''} ${row.middle || ''} ${row.last || ''}`,
    },
    {
      field: 'current_title',
      headerName: 'Current Title',
      description: 'Current Title scraped from linkedin',
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      width: 350,
      editable: false,
      hideable: true,
    },
    {
      field: 'current_company',
      headerName: 'Current Company',
      description: 'Current Company scraped from linkedin',
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      width: 350,
      editable: false,
      hideable: true,
    },
    {
      field: 'location_obj',
      valueGetter: (value: any) => {
        if ((value && value.city) || (value && value.country_code) || (value && value.location)) {
          const city = value.city ?? ''
          const country_code = value.country_code ?? ''
          const location = value.location ?? ''
          const retval = city ? city : location ? location : country_code ? country_code : ''
          return retval
        }
        return ''
      },
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      headerName: 'Location',
      description: 'Location scraped from linkedin',
      width: 300,
      editable: false,
      hideable: true,
    },
    {
      field: 'about',
      headerName: 'About',
      description: 'About scraped from Linkedin',
      width: 500,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={60} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'languages',
      valueGetter: (value: any) => {
        if (value && Array.isArray(value) && value.length > 0) {
          const langs = value
            .map((v) => {
              return v.title + '/' + v.subtitle
            })
            .join(', ')
          return langs
        }
        return ''
      },
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={30} />
          </Box>
        ) : (
          <></>
        ),
      headerName: 'Languages',
      description: 'Languages scraped from linkedin',
      width: 250,
      editable: false,
      hideable: true,
    },
    {
      field: 'created',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'Created',
      description: 'Date profile was created',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'updated',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'Updated',
      description: 'Date profile was updated',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'linkedin_url',
      headerName: 'Linkedin Url',
      width: 150,
      editable: false,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <a href={params.value}>
              Linkedin Url <OpenInNewIcon fontSize='small' />
            </a>
          </Box>
        ) : (
          <></>
        ),
    },
    // {
    //   field: 'is_faculty',
    //   headerName: 'Is Faculty',
    //   renderCell: (params: GridRenderCellParams<any, string>) => {
    //     return params.value ? <CheckCircleIcon fontSize='small' color={'success'} /> : <></>
    //   },
    //   width: 150,
    //   editable: false,
    //   hideable: true,
    // },
  ]

  return (
    <Box display={'flex'}>
      <Box sx={{height: 625, width: '100%'}}>
        {!data ? (
          <InteractiveLoading loading={true} success={false} />
        ) : (
          <DataGrid
            slots={{toolbar: GridToolbar}}
            getRowHeight={() => 'auto'}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  first: false,
                  middle: false,
                  last: false,
                  linkedin_url: false,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            density='compact'
          />
        )}
      </Box>
      {/* <BasicDialog
        maxWidth={'xl'}
        title={profile ? `Scraped profile for ${profile.linkedin_id}` : 'Raw scraped profile'}
        node={
          profile ? (
            <Box>
              <Typography>{JSON.stringify(profile.raw_profile)}</Typography>
            </Box>
          ) : (
            <></>
          )
        }
        show={show}
        setShow={setShow}
      /> */}
    </Box>
  )
}
